@import "@alltrails/shared/styles/landsEnd.scss";
.one {
  position: fixed;
  top: calc(var(--size-header-height-desktop) + $space-32 - 8px); // header height + design offset - google's padding
  right: calc($space-32 - 8px); // design - google's padding
  z-index: var(--z-index-sticky);
  display: none;

  @media (min-width: $breakpoint-sm-size) {
    display: block;
  }
}
